@font-face{
    font-family: 'Koulen';
    src: url('../../../public/fonts/Koulen-Regular.ttf');
}

@font-face{
    font-family: 'Gilroy';
    src: url('../../../public/fonts/Gilroy-Light.otf');
}

.points svg{
    position: absolute;
    right: 10px;
    bottom: 10px;
}

.lline svg{
    position: absolute;
    top: 0;
    left: 0;
    width: 40vw;
    height: 60vw;
}

.rline svg{
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 60vh;
}

.firstScreen {
    display: flex;
    width: 100vw;
    height: 100vh;
    justify-content: center;
    overflow-x: hidden;
}

//.bg {
//    position: absolute;
//    opacity: 0.2;
//    width: 99vw;
//    height: 100%;
//    z-index: -2;
//    background-image: url("../../../public/images/background4k.jpg");
//    background-size: cover;
//}


.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px;
    border-color: azure;
    width: 80vw;
    margin-bottom: 17vh;

}

.frog {
    margin: 0 1.7rem 0 8vw;
    z-index: -1;
    align-items: center;
    display: flex;
    width: calc(1rem + 20vw);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.middle {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.firstScreen h1{
    display: inline-flex;
    flex-direction: column;
    width: 100%;
    font-family: Koulen;
    font-size: calc(8vw);
    font-style: normal;
    font-weight: 400;
    line-height: calc(8vw);
}

.title0 {
    font-size: calc(1vw);
    line-height: calc(10px);
    font-family: Gilroy;
    color: #88FFFA;

}

.title1 {
    color: #E2E2E2;
}

.title2 {
    -webkit-text-stroke-color: var(--Color, #FFF);
    -webkit-text-stroke-width: 2px;
    color: #000;
}

.title3 {
    color: #E2E2E2;
    margin: 0 0 0 22vw;
}

.title0, .title1, .title2, .title3 {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

@media screen and (max-width: 869px) {
    .firstScreen h1{
        font-size: calc(10vw);
        line-height: calc(10vw);
    }

    .firstScreen {
        height: 100%;
    }

    .separator {
        display: none;
    }

    .wrapper {
        margin-bottom: 0;
    }
}

