$bg-img: url("../../../public/images/backStack.svg");

.blockTitle {
  font-family: Koulen;
  color: azure;
  flex-shrink: 0;
  margin: 0 0 0 3rem;
  font-size: 2.6rem;
  font-style: normal;
  line-height: normal;
}

.blockTitle img {
  height: 1.9rem;
  margin: 0 0 0 0.5rem;
}

.cell {
  display: flex;
  justify-content: left;
  --cell-gap: 1.2rem;
  padding: 10px;
  border-radius: 1.7rem;
  gap: var(--cell-gap);
  margin: 0;
  background-color: rgba(104, 94, 94, 0.17);
  aspect-ratio: 2.2/1;
}

.stackContainer {
  display: flex;
  flex-direction: column;
}

.stackDescription {
  display: flex;
  width: 100%;
  margin-top: 7px;
  flex-direction: column;
  align-items: flex-start;
}

.star {
  width: 14%;
}

.gradient {
  background: radial-gradient(40rem at 10% 70%, rgba(0, 243, 231, 1) 0%, rgba(139, 255, 250, 0.4) 0%, rgba(139, 255, 250, 0.3) 0%, rgba(139, 255, 250, 0.2) 0%, rgba(139, 255, 250, 1) 0%, transparent);
  position: absolute;
  width: 99vw;
  height: 300vh;
  z-index: -1;
}

.stars {
  display: flex;
  flex-direction: row;
  gap: 3px;
  margin-top: 5px;
  width: 90%;
}

.image {
  display: flex;
  --cell-gap: 1.2rem;
  margin-bottom: 0;
}

.board {
  --cell-gap: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  border-radius: 2.1875rem;
  grid-gap: var(--cell-gap);
  padding: var(--cell-gap);
}

.board p {
  color: azure;
  font-family: Gilroy-Medium;
  margin: 0;
  font-size: clamp(12px, 4vw, 20px);
}

.caption {
  color: #808080;
  font-size: 1.2rem;
  font-family: Gilroy-Medium;
  margin: 0 0 0 3rem;
}

@media screen and (max-width: 869px) {
  .blockTitle {
    font-size: 1rem;
  }

  .board {
    grid-template-columns: repeat(auto-fill, minmax(140px, 2fr));
    padding: 0;
    gap: 1rem;
  }

  .cell {
    aspect-ratio: 1/1;
    padding: 5px;
  }

  .cell img {
    height: 100%;
  }

  .caption {
    display: none;
  }

  .stackDescription {
    display: none;
  }

  .blockTitle img {
    height: 4vw;
  }
  .blockTitle {
    margin: 0;
  }
  .blockTitle span {
    font-size: calc(1rem + 3vw);
  }
}

