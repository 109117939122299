.Header img{
    display: flex;
    z-index: -1;
    margin: 20px auto;
}

.mainLogo {
    width: 15rem;
}

.Header {
    display: block;
    width: 100%;
    box-sizing: border-box;
}

.socialsBlock {
    position: absolute;
    right: 20px;
    top: 24px;
    display: flex;
}

.socialsBlock img{
    margin-top: 0;
}
