.separator {
  background-color: white;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.line {
  display: flex;
  white-space: nowrap;
  font-family: Koulen;
  font-size: 20px;
  animation: marquee 25s infinite linear;
  width: 104vw;
}

.separator__wave {
  overflow-x: hidden;
}

@keyframes marquee {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-110%);
  }
}
