.pageContent {
  width: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.pageTitle h2 {
  display: block;
  font-size: calc(2rem + 5vw);
  font-style: normal;
  font-weight: 400;
  color: white;
  margin: 2rem 0 1rem 0;
}


.pageTitleBYDWC h2 {
  display: block;
  font-size: calc(2rem + 5vw);
  font-style: normal;
  font-weight: 400;
  color: white;
  margin: 2rem 0 1rem 0;
  white-space: nowrap;
}


.BYDWC {
  line-height: 1rem;
  color: transparent;
  -webkit-text-stroke-color: #e2e2e2;
  -webkit-text-stroke-width: 2px;
}

.pageTitle span {
  white-space: nowrap;
}

.Content {
  padding-left: 2rem;
  padding-right: 2rem;
  width: 100%;
}


@media screen and (max-width: 869px) {
  .pageTitle h2 {
    font-size: calc(9vw);
    line-height: 100%;
  }

  .pageTitleBYDWC h2 {
    font-size: calc(6vw + 1rem);
    line-height: 100%;
    white-space: normal;
  }

  .BYDWC {
    color: white;
    -webkit-text-stroke-color: transparent;
  }
}