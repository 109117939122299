body {
  margin: 0;

  background-color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

* {
  font-family: Koulen;
}

@font-face {
  font-family: 'Gilroy-bold';
  src: url('../public/fonts/Gilroy-ExtraBold.otf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: url('../public/fonts/Gilroy-Medium.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy-Semibold';
  src: url('../public/fonts/Gilroy-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Seravek';
  src: url('../public/fonts/Seravek.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
