.WWMItem h2 {
  margin: 0;
  padding: 0;
  color: black;
  font-size: calc(1rem + 0.5vw);
}

.WWMItem p {
  color: black;
  margin-bottom: 10px;
}

.linkContent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  height: 100%;
}

//.WWMItem a {
.cardField {
  display: flex;
  font-size: 1em;
  color: black;
  text-decoration: none;
  flex-direction: column;
  padding: 1rem 1rem 1rem;
  width: 100%;
  height: 100%;
}

.WWMItem {
  display: flex;
  position: relative;
  bottom: 10px;
  border-radius: 10px;
  transition: all 0.3s ease;
  background-color: #8BFFFA;

}

.WWMItem:hover {
  background-color: #fff;
  color: #8BFFFA;
  bottom: 5px;
}


.WorkWithMeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.WWMTitleText1 {
  color: #fff;
  font-family: Seravek, cursive;
  display: flex;
  font-size: 4vw;
  line-height: 100%;
}

.WWMTitleText2 {
  line-height: 100%;
  font-size: 4vw;
  font-family: Seravek, cursive;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  -webkit-text-stroke-color: var(--Color, #8BFFFA);
  -webkit-text-stroke-width: 1px;
}

.cardTitle a {
  font-family: Seravek, serif;
}

.cardDescription {
  font-family: Seravek, serif;
  font-size: calc(0.5rem + 0.6vw);
}

.WWMTitle {
  margin-right: 5rem;
  width: 50%;
}

//.WWMContent {
//  display: grid;
//  grid-template-columns: repeat(2, 1fr);
//  grid-auto-rows: auto;
//  align-items: stretch;
//  grid-gap: 2rem;
//  margin-bottom: 5rem;
//}
// .itemBorder {
//    border: 1px solid #8BFFFA;
//    border-radius: 10px;
//    transition: all 0.3s ease;
//    display: flex;
//    padding-left: 10px;
//    padding-right: 10px;
//    max-width: 550px;
//    height: auto;
//  }

.WWMContent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; /* Ensures equal spacing between columns */
  margin-bottom: 5rem;
}

.itemBorder {
  border: 1px solid #8BFFFA;
  border-radius: 10px;
  transition: all 0.3s ease;
  padding: 0 5px 0 5px;
  margin-bottom: 2rem;
  width: 100%;
}

/* Set order for odd and even items to create masonry effect */
.itemBorder:nth-child(odd) {
  order: 1;
}

.itemBorder:nth-child(even) {
  order: 2;
}

.WWMContent::before,
.WWMContent::after {
  content: "";
  flex-basis: 100%;
  width: 0;
  order: 2;
}

.pageTitleWWM {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: baseline;
}


.pageTitleWWM h2 {
  float: left;
  display: block;
  color: white;
  font-size: calc(1rem + 6vw);
  font-weight: 400;
  margin: 0 0 1rem 0;
}

.links {
  display: flex;
  height: 20px;
  gap: 1rem;
  margin-left: 1rem;
}

.links a:hover {
    transform: scale(1.1);
}


@media screen and (max-width: 869px) {
  .WWMContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .WWMTitle {
    margin-right: 0;
    margin-bottom: 2rem;
    width: 100%;
  }

  .WWMTitleText1 {
    font-size: 6vw;
  }

  .WWMTitleText2 {
    font-size: 6vw;
  }

  .WWMItem h2 {
    font-size: 1.5rem;
  }

  .cardDescription {
    font-size: 1rem;
  }

  .cardField {
    padding: 1rem 1rem 1rem;
  }

  .WorkWithMeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .WWMContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .itemBorder {
    width: 100%;
  }
}
