.socials {
  display: grid;
  grid-gap: 2rem;
  height: 20px;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 0 20px;
}

.socials a {
  height: 20px;
}

//hover for svg
.socials a:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 869px) {
  .socials {
    display: none;
  }
}